/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_b91d35';
  font-style: normal;
  font-weight: 200 1000;
  font-display: optional;
  src: url(/_next/static/media/b60fc9d2d030b5e6.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_b91d35';
  font-style: normal;
  font-weight: 200 1000;
  font-display: optional;
  src: url(/_next/static/media/2c91708671b37a8b.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_b91d35';
  font-style: normal;
  font-weight: 200 1000;
  font-display: optional;
  src: url(/_next/static/media/c92ff110d0ef9b86.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_b91d35';
  font-style: normal;
  font-weight: 200 1000;
  font-display: optional;
  src: url(/_next/static/media/dc9ab78c2735f6b0.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_b91d35';
  font-style: normal;
  font-weight: 200 1000;
  font-display: optional;
  src: url(/_next/static/media/b89f66ecdb077e7f.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Nunito_Fallback_b91d35';src: local("Arial");ascent-override: 99.46%;descent-override: 34.73%;line-gap-override: 0.00%;size-adjust: 101.65%
}.__className_b91d35 {font-family: '__Nunito_b91d35', '__Nunito_Fallback_b91d35';font-style: normal
}

